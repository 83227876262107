@import '../../../css/functions';
@import '../../../css/skins/variables';
@import '../../../css/variables';

.dev-button-radio {
    .btn.active {
        background: $color-primary;
        border: 1px solid $color-primary;
        color: white;
        box-shadow: none;
    }
}
