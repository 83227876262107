@import '../../common/styles/variables';
@import '../variables';
@import "../../../css/functions";
@import "../../../css/mixins";


.dev-timeline {

    .timeline-parent {
        color: $cyan-timeline-parent-font;
        font-size: $cyan-timeline-parent-size;

        &:after {
            background-color: $cyan-timeline-timeline;
            width: 1px;
        }

        .timeline-parent-open, .timeline-parent-closed {
            background: $cyan-timeline-parent-background;

            &:hover{
                background-color: #599cff;
            }

            &:active{
                background-color: #2b71d9;
            }
        }
        .timeline-parent-open:after, .timeline-parent-closed:after {
        }
       
    }

    .timeline-item {
        .btn-open, .btn-close{
            &:hover{
                color: #3385ff;
            }

            &:active{
                color: #2b71d9;
            }
        }

        .time {
            // color: $cyan-timeline-parent-font;
            font-weight: $cyan-timeline-time-size;
        }

        .dot {
            background-color: $cyan-timeline-itemtitle-background;
        }

        .title-text {

        }

        .pointer{
            @include creat-pointer(10px, $cyan-timeline-timeline, 8px, #ffffff);
        }

        .content {
            background-color: $cyan-timeline-content-background;
            color: $cyan-timeline-content-font;
        }

    }
}
