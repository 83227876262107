@import '../../common/styles/variables';

.dev-anchor-wrapper {
    color: #0d3c5d;

    .dev-directory{
        ul.not-empty {
            &:before {
                background-color: $directory-circle-cyan;
            }
            &:after {
                opacity: 0.2;
                background-color: $directory-line-cyan;
                border: 2px solid $directory-line-cyan;
            }
    
            li.active {
                // background: $directtory-active;
                // border: 1px solid #d1dae3;
    
                &:before {
                    background: #3385ff;
                }
            }
        }        
    }
}
