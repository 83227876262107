@import '../../../css/functions';
@import '../../../css/skins/variables';
@import '../../../css/variables';


.dev-checkbox .dev-checkbox-item-wrapper > label,
.dev-radio .dev-radio-item-wrapper > label {
    &:before {
        border: 1px solid $gray-5;
    }

    &:focus:before {
        outline: 2px solid $color-primary-focus;
    }
}

.dev-checkbox .dev-checkbox-item-wrapper.checked > label {
    &:before {
        background: $color-primary;
        border: 1px solid $color-primary;
    }
    &:after {
        color: white;
    }
}

.dev-radio .dev-radio-item-wrapper.checked > label {
    &:before {
        background: white;
        border: 1px solid $color-primary;
    }
    &:after {
        color: $color-primary;
    }
}
