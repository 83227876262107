
@mixin theme(
    $checkbox-bg
) {
    .dev-form {
        .dev-datetimepicker {
            width: 100%;        
        }
        .dev-datetimepicker-range {
            width: 100%;
            .dev-datetimepicker {
                width: unset;
            }
        }
    }
    // checkbox
    // .icheckbox_square-green, .iradio_square-green {
    //     background-image: $checkbox-bg;
    // }

    // form[dev-form] .text-primary {
    //     white-space: pre-line;
    // }

    // .dev-form {
    //     hr {
    //         margin: 0;
    //     }
    //     .btn {
    //         padding: 4px 10px;
    //     }
    //     .action-btns {
    //         display:table;
    //         .btn {
    //             margin-right: 10px;
    //         }
    //     }
    //     // section 消除连续两个输入框间边框重复
    //     // span[ng-if="formItem.addonLeft"] + input:first-of-type {
    //     //     margin-right: -1px;
    //     // }
    //     .input-group-addon {
    //         border-color: #ccc;
    //         background-color: #eee;
    //         &:first-child {
    //             border-top-left-radius: 2px;
    //             border-bottom-left-radius: 2px;
    //         }
    //         &:last-child {
    //             border-top-right-radius: 2px;
    //             border-bottom-right-radius: 2px;
    //         }
    //         ~ input:not(:last-of-type) {
    //             border-right: 0;
    //         }
    //         + input:focus {
    //             border-right: 1px solid #167dd4;
    //         }
    //     }
    //     .form-control-static {
    //         // height: 32px;
    //         padding-top: 6px;
    //         padding-bottom: 6px;
    //     }
    //     .col-sm-3.control-label {
    //         line-height: 1.3;
    //         font-size: 14px;
    //     }
    //     .control-label {
    //         padding-right: 0;
    //         > span:first-child {
    //             color: red;
    //         }
    //     }
    //     .form-tip {
    //         padding-left: 0;
    //         margin-top: 2px;
    //     }
    //     &.form-group {
    //         &.clear {
    //             display: block;
    //             overflow: hidden;
    //         }
    //     }
    //     &.form-group.row {
    //         margin-bottom: 20px;
    //     }
    //     // tableForm 表单中不需要margin-bottom撑起行间距
    //     td .form-group.row {
    //         margin-bottom: 0;
    //     }
    //     .form-control.ui-select-toggle, .single-line {
    //         background-color: #ffffff;
    //         background-image: none;
    //         border: 1px solid #e5e6e7;
    //         border-radius: 2px;
    //         color: inherit;
    //         display: block;
    //         padding: 5px 12px;
    //         transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    //         width: 100%;
    //         font-size: 14px;
    //     }

    //     // .form-set {
    //     //     border: 1px solid #ddd;
    //     //     border-radius: 4px;
    //     //     padding: 0px 10px;
    //     //     position: relative;

    //     //     .form-set-title {
    //     //         width: 100%;
    //     //         border-bottom: 1px solid #ddd;
    //     //         padding: 0 10px;
    //     //         line-height: 32px;
    //     //     }
    //     // }

    //     .form-set, .form-array {
    //         border: 1px solid #ddd;
    //         border-radius: 4px;
    //         padding: 0 10px;
    //         position: relative;

    //         .form-set-title, .form-array-title {
    //             width: 100%;
    //             border-bottom: 1px solid #ddd;
    //             padding: 10px 0;
    //             margin-bottom: 44px;
    //             // line-height: 32px;
    //         }
    //         .form-set-title {
    //             margin-bottom: 20px;
    //         }

    //         .form-array-item {
    //             padding: 0 0 45px;
    //             margin-top: -25px;
    //             position: relative;
    //         }

    //         .minus {
    //             position: absolute;
    //             right: 0;
    //             top: -10px;
    //             color: #d9534f;
    //             cursor: pointer;
    //         }

    //         .add {
    //             position: absolute;
    //             right: 0;
    //             bottom: 7px;
    //             cursor: pointer;
    //         }
    //     }

    //     .form-select select {
    //         width: 100%;
    //     }

    //     .dev-form-comment {
    //         margin-top: 5px;
    //     }

    //     .dev-form-table-col {
    //         display: inline-block;
    //         padding: 0 10px;
    //         vertical-align:top;
    //     }

    //     .dev-form-table-split-hr {
    //         margin: 8px 0;
    //     }

    //     .dev-form-table-split-text {
    //         color: #aaa;
    //     }

    //     .dev-form-table-offset {
    //         padding-left: 55px;
    //     }

    //     .dev-form-table-tbody {
    //         position: relative;
    //     }

    //     .dev-form-table-tr {
    //         width: 100%;
    //         display: inline-block;
    //         // margin: 0 -8px;
    //     }

    //     .dev-form-table-tr-alert {
    //         padding-right: 40px;
    //     }

    //     .dev-form-table-action {
    //         position: absolute;
    //         top: 0;
    //         right: 15px;
    //     }

    //     .dev-form-table-button-add {
    //         position: absolute;
    //         top: 0;
    //         right: 15px;
    //     }

    //     .dev-form-tip-icon {
    //         position: relative;
    //         // top: 5px;
    //         cursor: pointer;
    //     }

    //     .ui-select-multiple.ui-select-bootstrap .ui-select-match-item.btn {
    //         background: #f1f1f1;
    //         border: 1px solid #ededed;
    //         border-radius: 2px;
    //         box-shadow: none;
    //         color: #333333;
    //         cursor: default;
    //         margin: 3px 0 3px 5px;
    //         // padding: 1px 4px;
    //         padding: 0 4px;
    //         font-size: 13px;
    //         font-weight: 500;
    //     }

    //     .ui-select-container {
    //         margin-top: 1px;
    //     }

    //     .ui-select-container.ui-select-bootstrap .ui-select-choices-row > span {
    //         margin: 0;
    //         font-size: 13px;

    //         &:hover, &:focus {
    //             background-color: #3875d7;
    //         }
    //     }

    //     .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    //         height: auto;
    //         margin-bottom: 0;
    //         vertical-align: top;
    //     }
    //     
    //     .ui-select-choices-group-label {
    //         display: list-item;
    //         font-weight: bold;
    //         font-size: 13px;
    //         cursor: default;
    //         padding: 5px 6px;
    //         margin: 0;
    //     }
    //     .ui-select-match > span {
    //         height: auto;
    //         overflow: auto;
    //         zoom: 1;
    //         min-height: 32px;
    //         max-height: 60px;
    //     }
    //     .ui-select-match-text {
    //         word-break: break-all;
    //         width: 100%;
    //         white-space: normal;
    //         span {
    //             vertical-align: top;
    //         }
    //     }

    //     .form-control.ui-select-multiple.ui-select-bootstrap {
    //         background-color: #FFFFFF;
    //         border: 1px solid #CBD5DD;
    //         border-radius: 2px;
    //         cursor: text;
    //         height: auto !important;
    //         margin: 0;
    //         min-height: 30px;
    //         padding: 1px;
    //     }

    //     .cancel-select-btn {
    //         transition: 0.3s;
    //         position: relative;
    //         top: -27px;
    //         right: 35px;
    //         float: right;
    //         font-size: 1.3em;
    //         line-height: 1;
    //         cursor: pointer;
    //         font-weight: bold;
    //         opacity: 0.5;
    //         height: 0px;
    //         width: 0px;

    //         &:hover {
    //             opacity: 1;
    //         }
    //     }

    //     .radio label {
    //         padding-left: 0;
    //     }
    // }

    // .dev-form-static {
    //     border: 1px solid green;
    //     margin-right: 10px;
    // }

    // .form-box {
    //     padding: 20px 0px 10px;
    //     margin: 10px 0 5px;
    //     border: 1px dotted #E5E1DF;
    // }

    // .dev-form-table {
    //     tbody tr td {
    //         padding-left: 10px;
    //         padding-right: 10px;
    //         .form-group {
    //             margin-bottom: 0;
    //         }
    //     }
    //     .table-bordered {
    //         border-left: 1px solid #ddd;
    //         border-right: 1px solid #ddd;
    // 
    //         &.text-right th, &.text-right td {
    //             text-align: left;
    //         }
    //         &.text-right th.alterable, &.text-right td.alterable {
    //             text-align: center;
    //         }
    //     }
    // }

    // // type inline

    // form[dev-form].type-inline {
    //     display: flex;

    //     // dev-timepanel较父元素下移1px是由‘.form-inline .form-group’中vertical-align:middle;引起的，
    //     // 在dev-timepanel组件特定类名中，vertical-align:top;权重已覆盖，在此规范用法
    //     // by liusq
    //     // dev-timepanel {
    //     //     margin-top: -1px;
    //     // }

    //     .btn-cancel {
    //         display: none;
    //     }

    //     // .col-sm-10 {
    //     //     width: auto !important;
    //     // }

    //     // ng-dev-form-decorator {
    //     //     line-height: 34px;
    //     // }
    //     ng-dev-form-decorator:not(:last-child) {
    //         // margin-right: 15px;
    //     }
    //     .ui-select-container {
    //         min-width: 160px!important;
    //         margin-top: 0;
    //     }
    //     // 针对inline做出的调整
    //     .dev-form.form-group.row {
    //         margin-left: 0;
    //         margin-right: 0;
    //     }
    // }

    // // 在表格中嵌套表单,display:table解决inline-block空白间隙失效的
    // .table .dev-form .action-btns {
    //     display: block;
    //     font-size: 0;
    // }

    // // 纵向窄表单-一种布局方式,暂时应用在带左侧副导航的详情页
    // .vertical-narrow-form {
    //     .form-set, .form-set .form-set-title {
    //         border: none;
    //         padding: 0;
    //         margin-bottom: 20px;
    //         .form-set-title {
    //             margin-bottom: 15px;
    //             ~ div {
    //                 .col-sm-3, .col-sm-6 {
    //                     padding: 0 20px;
    //                     margin: 0;
    //                     width: 100%;
    //                 }
    //             }
    //         }
    //     }
    //     .dev-form.form-group.row {
    //         margin-bottom: 15px;    
    //         // 消除原有单选框样式
    //         
    //     }
    //     .col-sm-3, .col-sm-6, .col-sm-9 {
    //         width: 100%;
    //     }
    //     ng-dev-form-decorator[type="buttonRadio"] {
    //         .btn-group, button {
    //             width: 100%;
    //         }
    //         label {
    //             margin-bottom: 15px;
    //         }
    //         dev-button-radio {
    //             display: block;
    //             .btn-group {
    //                 padding: 0 5px;
    //             }
    //             button {
    //                 color: #20b3f3;
    //                 border: none;
    //                 margin-bottom: 15px;
    //                 text-align: left;
    //                 overflow: hidden;
    //                 text-overflow:ellipsis;
    //                 white-space: nowrap;
    //                 &:last-child {
    //                     margin-bottom: 0;
    //                 }
    //                 &:hover {
    //                     background-color: #20b3f3;
    //                     color: #fff;
    //                 }
    //             }
    //         }
    //     }
    // }

    // // 纵向表单样式布局 
    // // 动作按钮和提示信息单独提出来,偏移
    // .vertical-form2-8-2 {
    //     .col-sm-3 {
    //         width: 16.666666%;
    //     }
    //     .col-sm-6 {
    //         width: 66.666666%;
    //     }
    //     .col-sm-9 {
    //         width: 83.333333%;
    //     }
    //     .col-sm-offset-3 {
    //         margin-left: 16.666666%;
    //     }
    // }
    // .vertical-form1-10-1 {
    //     .col-sm-3 {
    //         width: 8.333333%;
    //     }
    //     .col-sm-6 {
    //         width: 83.333333%;
    //     }
    //     .col-sm-9 {
    //         width: 91.666666%;
    //     }
    //     .col-sm-offset-3 {
    //         margin-left: 8.333333%;
    //     }
    // }
    // // 没有label和tip帮助信息,控件主体部分填充满
    // .vertical-form0-12-0 {
    //     .col-sm-3, .control-label, .form-tip {
    //         display: none;
    //         // width: 8.333333%;
    //     }
    //     .col-sm-6, .col-sm-9 {
    //         width: 100%;
    //     }
    //     .col-sm-offset-3 {
    //         margin-left: 0;
    //     }
    // }
    // .vertical-form3-9-0 {
    //     .form-tip {
    //         display: none;
    //         // width: 8.333333%;
    //     }
    //     .col-sm-3 {
    //         width: 25%;
    //     }
    //     .col-sm-6 {
    //         width: 75%;
    //     }
    //     .col-sm-9 {
    //         width: 100%;
    //     }
    //     .col-sm-offset-3 {
    //         margin-left: 25%;
    //     }
    // }
    // .vertical-form2-10-0 {
    //     .form-tip {
    //         display: none;
    //         // width: 8.333333%;
    //     }
    //     .col-sm-3 {
    //         width: 16.666666%;
    //     }
    //     .col-sm-6 {
    //         width: 83.333333%;
    //     }
    //     .col-sm-9 {
    //         width: 100%;
    //     }
    //     .col-sm-offset-3 {
    //         margin-left: 16.666666%;
    //     }
    // }
    // .vertical-form2-9-1 {
    //     .col-sm-3.control-label + div[ng-transclude] .col-sm-9 {
    //         width: 83.333333%;
    //     }
    //     .col-sm-3 {
    //         width: 16.666666%;
    //     }
    //     .col-sm-6 {
    //         width: 75%;
    //     }
    //     .col-sm-3.form-tip {
    //         width: 8.333333%;
    //     }
    //     .col-sm-9 {
    //         width: 91.666666%;
    //     }
    //     .col-sm-offset-3 {
    //         margin-left: 16.666666%;
    //     }
    // }
}
