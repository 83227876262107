@import '../../../css/functions';
@import '../../../css/mixins';
@import '../../../css/skins/variables';
@import '../../../css/variables';

.dev-slider {

   .slider-bar.slider-selection {
        background: $color-primary;
        box-shadow: inset 0px 0.5px 0.5px 0 rgba(0, 43, 70, 0.15);
   } 
}
