@import '../../common/styles/variables';

.dev-expanded-filter {
    .filter-item {
        border-bottom: 1px dashed $cyan-division;

        .filter-item-label {
            color: $cyan-plain;
        }

        .filter-item-element .btn-option:hover {
            border-color: $cyan-button-border;
        }

        .btn-primary.active, 
        .btn-primary:hover, 
        .open .dropdown-toggle.btn-primary {
            background-color: $cyan-button-border;
        }
    }
}


