@import '../../../css/functions';
@import "../../../css/skins/variables";
@import '../../../css/variables';

.dev-tabs {
    .nav-tabs li a {
        color: $gray-3;
        &:hover {
            color: $color-primary;
        }
    }
    .dev-tabs-wrapper > .nav-tabs li.active > a {
        position: relative;
        color: $color-primary;
        font-weight: bold;
        border-top: 1px solid $gray-6 !important;
        -webkit-box-shadow: inset -2px 2px 0 $color-primary;
        box-shadow: inset -2px 2px 0 $color-primary;

        // fix together use border-radius and box-shadow
        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 2px;
            bottom: 0;
            right: 0;
            width: 4px;
            background: white;
        }
    }
    .dev-tabs-wrapper.navigation {
       > .nav-tabs li.active > a {
            color: $color-primary;
            font-weight: bold;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-bottom: 2px solid $color-primary;
            border-top: none !important;

            &:after {
                display: none;
            }
       } 
    }

    .dev-tabs-wrapper.pill {
        .nav-tabs > li.active > a,
        .nav-tabs > li.active > a:hover,
        .nav-tabs > li.active > a:focus{
            color: white;
            font-weight: bold;
            background-color: $color-primary;
            border-top: none !important;
        }
        .nav-tabs > li.active > a:after {
            display: none;
        }
    }
}
