@import "../../common/styles/variables";

.dev-wiki-anchor.not-empty {
  li > div.active {
    a {
      color: #3385ff;
    }
    &::before {
      background-color: #3385ff;
    }
  }
}