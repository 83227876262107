@import '../../../css/functions';
@import '../../../css/mixins';
@import '../../../css/skins/variables';
@import '../../../css/variables';

.dev-treetable .form-checkbox {
    input[type=checkbox]:not([disabled]) + label:hover,
    input[type=checkbox]:not([disabled]):checked + label {
        border: 1px solid $color-primary;
    }

    input[type=checkbox]:checked + label {
        background: $color-primary;
        border: 1px solid $color-primary;
    }

    input[type=checkbox][disabled] + label {
        border: 1px solid $gray-5;
        color: $gray-4;
        background: $gray-7;
    }
    input[type=checkbox][disabled]:checked + label:after {
        color: $gray-4;
    }
}

